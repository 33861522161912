import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner"
import Seo from "../components/seo"
import horizontalCircuit from "../assets/images/homepage/circuitHorizontal.svg"
import videoBackImg from "../assets/images/homepage/videoSection.svg"
import arrow from "../assets/images/icons/arrow.svg"
import arrowWhite from "../assets/images/icons/arrowWhite.svg"
import backImg from "../assets/images/homepage/homeSwirl3.svg"
import backImg2Mobile from "../assets/images/company/companySwirl.svg"
import backImg2 from "../assets/images/company/companySwirl.svg"
import backImg4 from "../assets/images/homepage/learnmore.svg"
import backImg4logo from "../assets/images/homepage/ndbblockchain.svg"
import backImg4logoMobile from "../assets/images/homepage/ndbblockchainMobile.svg"
import backImg4Mobile from "../assets/images/homepage/learnMoreSwirlMobile.svg"
import backImg3 from "../assets/images/homepage/bottomSwirlImage2.svg"
import backImg3Mobile from "../assets/images/homepage/bottomSwirlImage2Mobile.svg"
import cityVision from "../assets/images/homepage/cityView3.svg"
import cityVisionMobile from "../assets/images/homepage/cityView2Mobile.svg"
import ndbcity from "../assets/images/homepage/ndbcity.svg"
import HomeDiscoverSection from "../components/homepage/HomeDiscoverSection"
import HomeAboutSection from "../components/homepage/HomeAboutSection"
import NDBsection1 from "../components/technology/NDBsection1"
import NDBWrapper from "../components/wrapper"
import CustomPartnersGrid from "../components/partners-grid/CustomPartnersGrid"
import { partnerImages, partnerImagesCarousel } from "../components/partners-grid/Data"
import HomeBottomSection from "../components/homepage/HomeBottomSection"
import TabsWithCarousel from "../components/tabs-with-carousel/TabsWithCarousel"
import { graphql, Link, useStaticQuery } from "gatsby"
import CustomButton from "../components/custom-button"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core"
import HomePartnerSection from "../components/homepage/PartnerSection"
import SubscribeForm from "../components/form/subscribe-form"

SwiperCore.use([Pagination, Navigation, Autoplay])

const Home = () => {
    const data = useStaticQuery(graphql`
        {
            HomeNews: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { regex: "/news/" }
                    frontmatter: { pageName: { eq: true } }
                }
                sort: { fields: frontmatter___newsImage___name }
            ) {
                totalCount
                nodes {
                    id
                    frontmatter {
                        title
                        slug
                        datePublished
                        author
                        pageName
                        newsImage {
                            publicURL
                        }
                    }
                }
            }
            HomeEvents: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/carousel/homepage/events/" } }
                sort: { fields: frontmatter___newsImage___base, order: ASC }
            ) {
                totalCount
                nodes {
                    id
                    frontmatter {
                        title
                        slug
                        datePublished
                        author
                        newsImage {
                            publicURL
                        }
                    }
                }
            }
        }
    `)

    const data1 = {
        backgoundText1: "techno",
        backgoundText2: "logy",
        NDBSectionTitle: "What is NDB ?",
        paragraphOverQuote1: (
            <>
                NDB or Nano Diamond Battery is an innovative energy generator and storage that
                redefines and revolutionizes the battery as we know it.
            </>
        ),
        paragraphOverQuote2: (
            <>
                Its long-lasting properties and longevity are ensured by converting the radioactive
                decay energy from nuclear waste into energy.
            </>
        ),
        paragraphOverQuote3: (
            <>
                NDB is tiny, modular, cost-effective, and scalable from chipset to industrial
                applications.
            </>
        ),
    }
    return (
        <Layout pageName="home">
            <Seo title="Home" />

            <Banner width="fullwidth" pageName="home">
                <img
                    src={backImg}
                    className="banner__overlay home-banner"
                    alt="banner background "
                />
                <img
                    src={backImg}
                    className="banner__overlay mobile-show "
                    alt="banner background "
                />
                <img src={backImg} className=" show1080 " alt="banner background " />
                <div className="home__banner-desc">
                    <span className="homeOverlay">LONGLIFE</span>
                    <p>SELF-CHARGING BATTERY</p>
                </div>
                <div className="banner__data ">
                    <div className="c-button">
                        <Link
                            to="/contact/"
                            target="_blank"
                            className="c-button__link banner__button"
                        >
                            <img src={arrow} alt="buttonImg" className="c-button__img " />
                            <span className="c-button__name text-left">Contact us</span>
                        </Link>
                    </div>
                </div>
            </Banner>

            <>
                <div className="mobile-hide">
                    <HomePartnerSection width="fullwidth" />
                </div>

                <div className="mobile-show">
                    <div className="Partner_cards_mobile slider-bottom">
                        <Swiper
                            slidesPerView={2}
                            slidesPerGroup={2}
                            spaceBetween={10}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            speed={2000}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                        >
                            {partnerImagesCarousel.map((item, index) => (
                                <SwiperSlide className="Clogo">
                                    <img src={item.logo} className="" alt="news img" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </>

            <NDBsection1
                backgoundText1={data1.backgoundText1}
                backgoundText2={data1.backgoundText2}
                NDBSectionTitle={data1.NDBSectionTitle}
                paragraphOverQuote1={data1.paragraphOverQuote1}
                paragraphOverQuote2={data1.paragraphOverQuote2}
                paragraphOverQuote3={data1.paragraphOverQuote3}
                backgoundText1Style="top right"
                backgoundText2Style="bottom-20  left"
                buttonLink="#"
            />
            <NDBWrapper width="fullwidth">
                <div className="home-circuitHorizontal">
                    <img src={horizontalCircuit} alt="horizontalCircuit" />
                </div>
            </NDBWrapper>

            <HomeAboutSection />

            <HomeDiscoverSection
                pageName="home"
                backImg={videoBackImg}
                bannerLink="Play Video"
                DiscoverTitle="Discover how we make lifelong self-charging batteries possible"
                width="fullwidth"
                descriptionText
            />
            <HomeBottomSection
                bannerLink="/careers/"
                arrow={arrow}
                bannerLinkName="Believe in NDB"
                sectionDesc="NDB values and "
                sectionDesc2="responsibilities"
                backImg={backImg2}
                backImg3Mobile={backImg2Mobile}
                heightAuto
                bottom
                lineBreak={true}
                width="fullwidth"
                imgPosition="object-pos-cener"
                descriptionText
            />
            <HomeBottomSection
                bannerLink="https://ndb.money"
                arrow={arrowWhite}
                bannerLinkName="Learn more"
                sectionDescLogo="Ecosystem, tokens, and solutions"
                sectionDescLogoMobile="Ecosystem"
                backImg={backImg4}
                backImgLogo={backImg4logo}
                backImgLogoMobile={backImg4logoMobile}
                backImg3Mobile={backImg4Mobile}
                imageWidth="w-100"
                bottom
                width="fullwidth"
                colorWhite
                imgPosition="object-pos-left"
                id="blockchain"
                descriptionText
            />

            <NDBWrapper width="fullwidth">
                <div className="city-vision ">
                    <img src={ndbcity} alt="ndb city" className="mobile-hide city-vision__image" />
                    <p className="title global-title-font mobile-show text-green">
                        <img
                            src={ndbcity}
                            alt="ndb city"
                            className="mobile-show  city-vision__image"
                        />
                    </p>
                    <p className="under-title mobile-hide">Innovation ecosystem</p>
                    <p className="desc mobile-hide">
                        An advanced tech hub to deliver interdisciplinary innovative solutions.
                    </p>
                    <p className="desc mobile-show">
                        An advanced techhub to deliver interdisciplinary innovative solutions.
                    </p>
                    <CustomButton
                        buttonTitle="Explore the vision"
                        buttonLink="https://ndb.city"
                        buttonWidth="w-45"
                        buttonImg={arrow}
                        flex="flex-left"
                        textAlign="text-left"
                        type="external"
                    />
                    <img src={cityVision} alt="pre sale page" className="image mobile-hide" />
                    <img
                        src={cityVisionMobile}
                        alt="pre sale page"
                        className="image mt-0  mobile-show"
                    />
                </div>
            </NDBWrapper>
            <TabsWithCarousel data={data} display="events" />
            <>
                <div className="mobile-hide">
                    <CustomPartnersGrid data={partnerImages} />
                </div>

                <div className="mobile-show">
                    <div className="partners-section-mobile slider-bottom">
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={5}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            speed={2000}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                        >
                            {partnerImages.map(({ logo, link }, index) => (
                                <SwiperSlide className="partners-section-mobile__item" key={index}>
                                    <a href={link}>
                                        <img src={logo} className="" alt="news img" />
                                    </a>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </>

            <HomeBottomSection
                bannerLink="/careers/"
                arrow={arrowWhite}
                bannerLinkName="Join our team"
                sectionDesc="To invest in your abilities"
                sectionDesc2="join us at NDB"
                lineBreak={true}
                backImg={backImg3}
                backImg3Mobile={backImg3Mobile}
                bottom
                width="fullwidth"
                colorWhite
                id="homeBottomSection3"
                descriptionText
            />
            <SubscribeForm />
        </Layout>
    )
}
export default Home
